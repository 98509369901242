export const FlatDrummer = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 563.6 616.708">
  <g id="Group_45" data-name="Group 45" transform="translate(-1567.1 -225.992)">
    <g id="Group_205" data-name="Group 205">
      <g id="Group_204" data-name="Group 204">
        <path id="Path_155" data-name="Path 155" d="M2061,669.2V805.6c0,6.2-.2,12.5,0,18.8v.3a1.652,1.652,0,0,0,3.3,0V688.3c0-6.2.2-12.5,0-18.8v-.3a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_141" data-name="Group 141">
      <g id="Group_140" data-name="Group 140">
        <path id="Path_97" data-name="Path 97" d="M1696.7,399.2l-32.5,32.5L1645,450.9c-1.5,1.5-4.7,3.6-4.8,5.9-.2,6.3,0,12.7,0,19.1V824.6a1.652,1.652,0,0,0,3.3,0V471.1c0-4.7.4-9.6,0-14.3v-.6c-.2.4-.3.8-.5,1.2l18.8-18.8,30.2-30.2,6.9-6.9a1.592,1.592,0,1,0-2.2-2.3Z" fill="#2f2c2b"/>
      </g>
    </g>
    <path id="Path_98" data-name="Path 98" d="M2118,669.2H2007.3a12.718,12.718,0,0,1-12.7-12.7V636.1a12.718,12.718,0,0,1,12.7-12.7H2118a12.718,12.718,0,0,1,12.7,12.7v20.4A12.718,12.718,0,0,1,2118,669.2Z" fill="#444"/>
    <path id="Path_100" data-name="Path 100" d="M1844,837.4l9-8.8a2.814,2.814,0,0,0,.9-2.3l-3-33,29.6,1.3,1.4,44.8a3.058,3.058,0,0,1-3,2.9l-32.8.3A3.01,3.01,0,0,1,1844,837.4Z" fill="#5f6479"/>
    <path id="Path_101" data-name="Path 101" d="M1854.6,838.7l-34.8-16.6a10.817,10.817,0,0,0-4.7-1.1h-14.7l-33.5,21.5,86.8-.1A1.945,1.945,0,0,0,1854.6,838.7Z" fill="#2f2c2b"/>
    <path id="Path_102" data-name="Path 102" d="M2015.4,567s-113.5-31.2-150.6-.8c-48.2,39.4-13.6,233.7-13.6,233.7h30.5s11.4-146.5,7.7-182.3c-3.7-35.9,10,5.4,18.2,7.9,1.8.6,145.9,19.4,167.5,11.8Z" fill="#157fe5"/>
    <g id="Group_144" data-name="Group 144">
      <path id="Path_103" data-name="Path 103" d="M2051.3,298.6c-9.4-1.5-14.7.2-23.6,3.7-8.8,3.4-16.6,9.1-25.3,12.6a54.752,54.752,0,0,1-23,3.6c-5.7-.4-11.4-1.6-17.1-1.6s-11.8,1.6-15.6,5.9c-3.1,3.5-4.3,8.4-7.5,11.7-4.5,4.7-11.7,5.4-18.2,5.6-2.3.1-4.8.2-6.7,1.6-4,3-2.4,9.4,0,13.8a93.432,93.432,0,0,0,10.5,15.7,18.646,18.646,0,0,0,5.9,5.3c2.4,1.2,5.5,1.2,7.5-.5-1.9,1.8-3.8,3.5-5.7,5.3a102.788,102.788,0,0,0,16,16.3c2,1.6,4.1,3.3,6.7,3.8,5.5,1.3,10.9-2.7,14.7-6.9,3.9-4.2,7.7-9.1,13.2-10.4,6.2-1.5,12.9,1.9,19.1.2,2.8-.8,5.2-2.6,8-3.5,3.5-1.2,7.2-.9,10.9-1A47.807,47.807,0,0,0,2065,317" fill="#72a58f"/>
    </g>
    <g id="Group_145" data-name="Group 145">
      <path id="Path_104" data-name="Path 104" d="M2052.4,300.1l-1.1-1.5c-9.4-1.5-14.7.2-23.6,3.7-8.8,3.4-16.6,9.1-25.3,12.6a54.752,54.752,0,0,1-23,3.6c-5.7-.4-11.4-1.6-17.1-1.6h-1.5a7.662,7.662,0,0,1,4.2,3.5,9.62,9.62,0,0,1-.7,9c-1.4,2.4-3.7,4.4-4.8,7a9.237,9.237,0,0,0,11,12.6c2.8-.8,5-2.9,7.8-3.8a10.237,10.237,0,0,1,9.1,1.5c1.2.9,2.2,2.1,3.6,2.7,3.1,1.3,6.6-.9,8.2-3.8s2-6.3,3.1-9.4c3.6-11.1,14.6-17.9,24.8-23.7C2035.2,307.8,2043.5,303.1,2052.4,300.1Z" fill="#9ad3bb"/>
    </g>
    <g id="Group_146" data-name="Group 146">
      <path id="Path_105" data-name="Path 105" d="M1980.1,493c9.1-9.8,22.5-26,45.8-60.5L1998,406.7c-27,36.5-41.2,55.3-49.1,70.3-4,7.6-10.1,16.2-5.5,23.4,3.9,6.1,12.6,7.7,19.5,5.4C1969.6,503.6,1975.1,498.3,1980.1,493Z" fill="#d05c60"/>
    </g>
    <path id="Path_106" data-name="Path 106" d="M1970.4,490a14.968,14.968,0,0,1-18.7,16.3c-13.8-3.6-21.2-8.8-45.4-30.6-32.3-29.1-43.7-45.4-43.7-45.4l8.1-12s86.4,39.9,96.3,60.7A42.26,42.26,0,0,1,1970.4,490Z" fill="#d05c60"/>
    <path id="Path_107" data-name="Path 107" d="M1940.6,436.9h0c.7-1.1.8-2.5-.2-4.4-1.6-2.9-5.2-5.7-7.1-4.7-1.9,1.1-1.4,5.6.1,8.5,1,1.9,2.3,2.6,3.6,2.5h0l51.1,99a4.84,4.84,0,0,0,6.6,2.1h0a4.82,4.82,0,0,0,1.8-6.7Z" fill="orange"/>
    <path id="Path_108" data-name="Path 108" d="M1885.6,427.2c-4.8-5.7-5.6-8.2-4-16.6s-3.8-22-6.8-18.1-2.2,9.1-2.2,9.1-19.2-13.2-21.5-10.7c-2.3,2.4-2.6,6.9-1.9,8.1.8,1.2-5.3,11.9-1.9,19.8,2.2,5.2,17.3,15.6,27,21.8a8.171,8.171,0,0,0,12.5-5.2C1887.5,432.3,1887.4,429.3,1885.6,427.2Z" fill="#ffd1bb"/>
    <g id="Group_147" data-name="Group 147">
      <path id="Path_109" data-name="Path 109" d="M2002.9,498.9c0-.1-.1-.2-.1-.3-3.1-9.9-7.6-19.3-10.4-29.3-3.4-12.3-.9-14.2,1.6-26.7,2-9.9-1.4-20.1,1-30a19.062,19.062,0,0,1,9.6-12.4c11.4-6.2,25.6-2.6,37.9-2.6a473.45,473.45,0,0,1,49.1,2.5c31.2,3.1,23.4,23,17,50.7-2.8,11.9-11.5,26.2-11.7,38.6-.4,33.4.7,36.4,12.2,71.9,1,2.9,6.1,15.9,3.1,16.7-30.1,7.6-66.5-4.6-98.9-11.3,1.9-12.2-.3-24.5-2.6-36.6A254.124,254.124,0,0,0,2002.9,498.9Z" fill="#d05c60"/>
    </g>
    <g id="Group_148" data-name="Group 148">
      <path id="Path_110" data-name="Path 110" d="M2096.9,489.4c.1-11.5,7.8-24.8,11-36.2a17.389,17.389,0,0,0-6.1-3c-9.3-2.3-18.6,4.2-24.4,12-2.3,3.2-4.5,6.7-7.9,8.6a19.769,19.769,0,0,1-6.7,1.9c-17.4,2.9-46.7,4.2-68.1,4,2.6,7.3,5.7,14.5,8,21.9,0,.1.1.2.1.3a273.959,273.959,0,0,1,7.9,31.2c2.2,11.7,4.3,23.6,2.8,35.5.6.5,1.2,1,1.9,1.5,27.8,5.9,58.4,15.5,85.4,12.9a43.7,43.7,0,0,0,4.6-1.8c4.2-1.9-.2-29.1,3.2-32.2C2097.2,511,2096.6,522.5,2096.9,489.4Z" fill="#af4850"/>
    </g>
    <path id="Path_112" data-name="Path 112" d="M2059.8,356.2l5.4,47.6-32.6,1.8-3.4-41.4Z" fill="#ffd1bb"/>
    <path id="Path_113" data-name="Path 113" d="M1919.8,837.4l9-8.8a2.814,2.814,0,0,0,.9-2.3l-7.8-33,29.6,1.3,6.2,44.8a3.058,3.058,0,0,1-3,2.9l-32.8.3A3.01,3.01,0,0,1,1919.8,837.4Z" fill="#535a7b"/>
    <g id="Group_151" data-name="Group 151">
      <line id="Line_2" data-name="Line 2" transform="translate(2080.3 635.1)" fill="#2f2c2b"/>
      <path id="Path_114" data-name="Path 114" d="M1876.7,574.4c.1-.2.2-.3.3-.5,8.7-12,22.6-15,36.1-18.6a259.313,259.313,0,0,1,42-7c11.9-1.1,38.9-1.6,50.9-1.8,4-.1,22.5.6,26.3,2.1l43.9,25.2s44.9,57.7,0,63.3-148.5-26.3-148.5-26.3,8.6,5.3,16.1,65.6c3.1,24.8,9.4,123.5,9.4,123.5h-31.1c-11.2-31.9-21.2-64.2-30.3-96.8-8-28.8-16.6-58.5-20.9-88.2C1869,601.3,1868.4,586.2,1876.7,574.4Z" fill="#1c90ff"/>
      <g id="Group_150" data-name="Group 150">
        <path id="Path_116" data-name="Path 116" d="M2006,546.5c42.2,0,78.2.9,102.6-.6,6.5,15.7,13.6,39.9,8.8,56.8a45.332,45.332,0,0,1-7,14.3c-8.1,10.8-20.7,18.5-34.1,20.1a3.752,3.752,0,0,0-1.1.1C2079,588,2006,546.5,2006,546.5Z" fill="#1c90ff"/>
      </g>
    </g>
    <path id="Path_117" data-name="Path 117" d="M2019.3,321.3c-2.4,6.8-4,15.2-10,19.1a2.089,2.089,0,0,0-.6,3.1l.8,1.2a2.819,2.819,0,0,1,.3,2.8c-2,5.3-7.6,24.5,9,28.3,12.1,2.7,25.4,4.1,36.4-2.3,20-11.7,31-41.9,17.2-62.2-12.8-18.8-41.7-13.2-51.4,5.8C2020.4,318.4,2019.9,319.8,2019.3,321.3Z" fill="#ffd1bb"/>
    <g id="Group_152" data-name="Group 152">
      <path id="Path_118" data-name="Path 118" d="M2062.9,306.7c-5.8,3.9-11.6,8-16.1,13.3-4.5,5.4-7.5,12.4-6.6,19.3a10.707,10.707,0,0,0,2.2,5.5,5.993,5.993,0,0,1,1.6,2.3c.1.6,0,1.3.1,1.9.2,1.1-.8-.8,0,0,1.8,1.9-1-2.4,0,0,2.6,6.5,12.5,22.1,18,26.4a102.311,102.311,0,0,0,17.2-20.5,39.373,39.373,0,0,0,5.2-11.2c1.4-5.8.4-12-1.6-17.6-5.7-15.6-11.5-18-15.9-21-2-1.3-2.4,2.3-2.6.8-.3-2.4-.8-.6-1.8,1.5" fill="#9ad3bb"/>
    </g>
    <path id="Path_119" data-name="Path 119" d="M2034.4,340.7c-3.6,3.3-4.4,8.2-1.9,11s7.5,2.3,11.1-1,4.4-8.2,1.9-11S2038,337.4,2034.4,340.7Z" fill="#ffd1bb"/>
    <g id="Group_160" data-name="Group 160">
      <g id="Group_159" data-name="Group 159">
        <path id="Path_123" data-name="Path 123" d="M2048.2,298a37.641,37.641,0,0,1,10.7,2.1c6.4,2.2,10.2,6.4,12,7.7,4.6,3.4-3.5-1.6-2.7-1,.4.3-1.2-.8-1.6-.5-11.2,6.6-29.3,7.4-42.5,5a2.121,2.121,0,0,1-1.3-3.4C2028.5,300.3,2038.6,297.5,2048.2,298Z" fill="#9ad3bb"/>
      </g>
    </g>
    <g id="Group_161" data-name="Group 161">
      <path id="Path_124" data-name="Path 124" d="M2119,490.5c.5-13.3,0-34.3-5-75.7l-38-1.3c3.4,45.3,5,68.7,8.9,85.3,1.9,8.4,2.9,18.9,11.1,21.3,7,2,14.5-2.4,18.3-8.7C2118,505.2,2118.7,497.7,2119,490.5Z" fill="#d05c60"/>
    </g>
    <path id="Path_125" data-name="Path 125" d="M2111.3,494.9c7.9,7,6,19.9-3.5,24.6-12.8,6.3-21.8,7.2-54.4,6.5-43.5-.9-62.7-5.7-62.7-5.7L1989,506s91.4-26.3,112.5-17A35.171,35.171,0,0,1,2111.3,494.9Z" fill="#d05c60"/>
    <path id="Path_126" data-name="Path 126" d="M2001,503.9c-7.3-1.3-9.6-2.6-13.9-10-4.2-7.5-17.1-14.2-16.9-9.3s4.2,8.4,4.2,8.4-26.2,4.6-26.3,8c-.1,3.3,5.5,4.8,6.8,5.2,1.4.4,3.7,12.5,11.4,16.3,5.1,2.5,23.3.6,34.7-.9a8.277,8.277,0,0,0,6.2-12.1C2005.7,506.6,2003.7,504.3,2001,503.9Z" fill="#ffd1bb"/>
    <g id="Group_167" data-name="Group 167">
      <g id="Group_166" data-name="Group 166">
        <path id="Path_129" data-name="Path 129" d="M1994.6,431.7c.3,7.8-3,15-4.1,22.5a28.527,28.527,0,0,0,.3,10.9,76.635,76.635,0,0,0,3.9,11.4c.4,1,2,.5,1.6-.4a70.6,70.6,0,0,1-3.9-11.2,28.319,28.319,0,0,1-.1-11.6c1.3-7.2,4.3-14.2,4-21.7-.1-1-1.8-1-1.7.1Z" fill="#2f2c2b"/>
      </g>
    </g>
    <path id="Path_130" data-name="Path 130" d="M1841.1,345.6h0c.9-1,1.2-2.4.4-4.4-1.1-3.1-4.3-6.4-6.3-5.6s-2.2,5.4-1.1,8.4c.8,2,1.9,2.9,3.2,3h0l36.3,105.3a4.823,4.823,0,0,0,6.3,3h0a4.9,4.9,0,0,0,2.8-6.4Z" fill="orange"/>
    <g id="Group_170" data-name="Group 170">
      <path id="Path_132" data-name="Path 132" d="M2019.8,426.1a19.9,19.9,0,0,0,6.7,3.7c11,3.7,23.4,1.1,33.5-4.6s18.3-14.2,25.9-23l2.1-2.4c-15.1-1.4-30.2-2.1-45.4-2.1-7.5,0-15.7-1.3-23.6-1a36.294,36.294,0,0,0-3.8,12.5C2014.4,415.2,2015.3,421.9,2019.8,426.1Z" fill="#ffd1bb"/>
    </g>
    <g id="Group_171" data-name="Group 171">
      <path id="Path_133" data-name="Path 133" d="M1833.4,697.8c0-76.2-20.5-138.6-46.5-144.4V553H1622.1V842.7h164.8v-.4C1812.9,836.4,1833.4,774,1833.4,697.8Z" fill="orange"/>
      <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="50.5" cy="144.8" rx="50.5" ry="144.8" transform="translate(1567.1 553)" fill="#444"/>
    </g>
    <g id="Group_173" data-name="Group 173">
      <g id="Group_172" data-name="Group 172">
        <path id="Path_134" data-name="Path 134" d="M1657.4,613.6h168.1a1.652,1.652,0,0,0,0-3.3H1657.4a1.652,1.652,0,0,0,0,3.3Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_188" data-name="Group 188">
      <g id="Group_178" data-name="Group 178" transform="translate(0.2)">
        <path id="Path_135" data-name="Path 135" d="M1898.508,613.5H1768.092a5.8,5.8,0,0,1-5.792-5.8V561.1a5.8,5.8,0,0,1,5.792-5.8h130.416a5.8,5.8,0,0,1,5.792,5.8v46.6A5.8,5.8,0,0,1,1898.508,613.5Z" fill="#444"/>
        <path id="Path_136" data-name="Path 136" d="M1914.708,598.9H1751.892a7.337,7.337,0,0,1-7.292-7.3V533.5a7.337,7.337,0,0,1,7.292-7.3h162.817a7.337,7.337,0,0,1,7.292,7.3v58.1A7.273,7.273,0,0,1,1914.708,598.9Z" transform="translate(0)" fill="orange"/>
        <path id="Path_137" data-name="Path 137" d="M1914.708,526.2H1751.892a7.337,7.337,0,0,0-7.292,7.3V553H1922V533.6A7.358,7.358,0,0,0,1914.708,526.2Z" transform="translate(0)" fill="orange"/>
        <g id="Group_175" data-name="Group 175" transform="translate(1742.92 590.025)">
          <g id="Group_174" data-name="Group 174">
            <path id="Path_138" data-name="Path 138" d="M1920.382,591.6a5.718,5.718,0,0,1-5.9,5.6H1756.391a27.129,27.129,0,0,1-5.7-.1,5.656,5.656,0,0,1-4.4-5.5,1.651,1.651,0,0,0-3.3,0,8.938,8.938,0,0,0,8.994,8.9h159.094c3,0,6.1.3,8.694-1.5a8.807,8.807,0,0,0,3.9-7.4c0-2.1-3.2-2.1-3.3,0Z" transform="translate(-1743 -590.025)" fill="#2f2c2b"/>
          </g>
        </g>
        <g id="Group_177" data-name="Group 177" transform="translate(1742.9 524.581)">
          <g id="Group_176" data-name="Group 176">
            <path id="Path_139" data-name="Path 139" d="M1746.2,533.5a5.717,5.717,0,0,1,5.894-5.6h158.025a27.1,27.1,0,0,1,5.694.1,5.656,5.656,0,0,1,4.4,5.5,1.65,1.65,0,0,0,3.3,0,8.936,8.936,0,0,0-8.99-8.9H1755.486c-3,0-6.093-.3-8.69,1.5a8.808,8.808,0,0,0-3.9,7.4,1.648,1.648,0,0,0,3.3,0Z" transform="translate(-1742.9 -524.581)" fill="#2f2c2b"/>
          </g>
        </g>
      </g>
      <g id="Group_187" data-name="Group 187">
        <g id="Group_180" data-name="Group 180">
          <g id="Group_179" data-name="Group 179">
            <path id="Path_140" data-name="Path 140" d="M1774.1,526.2v70.5a1.652,1.652,0,0,0,3.3,0V526.2a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
          </g>
        </g>
        <g id="Group_182" data-name="Group 182">
          <g id="Group_181" data-name="Group 181">
            <path id="Path_141" data-name="Path 141" d="M1812.5,526.2v70.5a1.652,1.652,0,0,0,3.3,0V526.2a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
          </g>
        </g>
        <g id="Group_184" data-name="Group 184">
          <g id="Group_183" data-name="Group 183">
            <path id="Path_142" data-name="Path 142" d="M1850.9,526.2v70.5a1.652,1.652,0,0,0,3.3,0V526.2a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
          </g>
        </g>
        <g id="Group_186" data-name="Group 186">
          <g id="Group_185" data-name="Group 185">
            <path id="Path_143" data-name="Path 143" d="M1889.3,526.2v70.5a1.652,1.652,0,0,0,3.3,0V526.2a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
          </g>
        </g>
        <g id="Group_236" data-name="Group 236" transform="translate(-31 7.449)">
          <g id="Group_179-2" data-name="Group 179" transform="translate(1774.1 524.625)">
            <path id="Path_140-2" data-name="Path 140" d="M1774.1,525.923v58.113c0,1.731,3.3,1.731,3.3,0V525.923c0-1.731-3.3-1.731-3.3,0Z" transform="translate(-1774.1 -524.625)" fill="#2f2c2b"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Group_190" data-name="Group 190">
      <g id="Group_189" data-name="Group 189">
        <path id="Path_144" data-name="Path 144" d="M1665.2,694.2h168.1a1.652,1.652,0,0,0,0-3.3H1665.2c-2.1.1-2.1,3.3,0,3.3Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_192" data-name="Group 192">
      <g id="Group_191" data-name="Group 191">
        <path id="Path_145" data-name="Path 145" d="M1657.4,774.9h168.1a1.652,1.652,0,0,0,0-3.3H1657.4a1.652,1.652,0,0,0,0,3.3Z" fill="#2f2c2b"/>
      </g>
    </g>
    <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="73.9" cy="130.5" rx="73.9" ry="130.5" transform="translate(1568.588 330.502) rotate(-45)" fill="#e3b764"/>
    <g id="Group_193" data-name="Group 193">
      <path id="Path_146" data-name="Path 146" d="M1805.4,462.8l1-1a16.356,16.356,0,0,0-.2-2.2,3.837,3.837,0,0,0-1.1-2.5c-2.5-2.1-7.6,2-9-.9a27.512,27.512,0,0,0,3.5-11.6c-8.7,2.1-18.2-1.6-24.6-7.9-6.5-6.3-10.3-14.7-12.8-23.4-1.6-5.3-2.9-11.2-7.3-14.7-4.4-3.4-10.8-3.4-15.1-7-4.5-3.8-5.4-10.2-6.6-15.9s-4-12.2-9.7-13.5c-2.4-.6-5-.1-7.5-.3-2.5-.3-5.2-1.8-5.5-4.2a11.407,11.407,0,0,1,.2-2.6c.4-6.3-7.2-9.6-13.2-11.3a238.8,238.8,0,0,1-37.6-13.9c-19.8-9.2-40-23.3-44.3-44.7l-.9.8c-19.6,31.6-.7,90,46.1,136.9C1711.8,473.7,1776.6,491.6,1805.4,462.8Z" fill="#d39f4c"/>
    </g>
    <g id="Group_201" data-name="Group 201">
      <g id="Group_197" data-name="Group 197">
        <g id="Group_196" data-name="Group 196">
          <path id="Path_148" data-name="Path 148" d="M1920.9,406.8V796.5c0,5.3-.1,10.5,0,15.8v.7a1.652,1.652,0,0,0,3.3,0V423.3c0-5.3.1-10.5,0-15.8v-.7a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
        </g>
      </g>
      <g id="Group_200" data-name="Group 200">
        <path id="Path_149" data-name="Path 149" d="M1929.8,502.462l75.7-14.494c2.8-.543,2.1-2.768-.9-2.768H1839.7c-2.9,0-3.6,2.226-.9,2.768l75.7,14.494A40.3,40.3,0,0,0,1929.8,502.462Z" transform="translate(-0.073 7)" fill="#d39f4c"/>
        <path id="Path_151" data-name="Path 151" d="M1914.5,457.94l-75.7,14.981c-2.8.561-2.1,2.862.9,2.862h164.9c2.9,0,3.6-2.3.9-2.862l-75.7-14.981A41.261,41.261,0,0,0,1914.5,457.94Z" transform="translate(0 11.517)" fill="#e3b764"/>
      </g>
      <path id="Path_152" data-name="Path 152" d="M1905.2,803.2l-55.3,35.6a2.01,2.01,0,0,0,1.1,3.7h143a2.01,2.01,0,0,0,1.1-3.7l-55.3-35.6A31.892,31.892,0,0,0,1905.2,803.2Z" fill="#2f2c2b"/>
      <path id="Path_153" data-name="Path 153" d="M1926.8,448.4h-9.4a2.9,2.9,0,0,1-2.9-2.9v-4.4a2.9,2.9,0,0,1,2.9-2.9h9.4a2.9,2.9,0,0,1,2.9,2.9v4.4A2.9,2.9,0,0,1,1926.8,448.4Z" transform="translate(0.45 20.342)" fill="#2f2c2b" stroke="#2f2c2b" strokeMiterlimit="10" strokeWidth="1.096"/>
    </g>
    <g id="Group_207" data-name="Group 207">
      <g id="Group_206" data-name="Group 206">
        <path id="Path_156" data-name="Path 156" d="M1831.7,609.9V822.7c0,2.9-.1,5.8,0,8.7v.4a1.652,1.652,0,0,0,3.3,0V619c0-2.9.1-5.8,0-8.7v-.4a1.652,1.652,0,0,0-3.3,0Z" fill="#2f2c2b"/>
      </g>
    </g>
    <path id="Path_157" data-name="Path 157" d="M2054,822.9l-27.6,17.8a1.023,1.023,0,0,0,.5,1.9h71.4a1.019,1.019,0,0,0,.5-1.9l-27.6-17.8A15.777,15.777,0,0,0,2054,822.9Z" fill="#2f2c2b"/>
    <path id="Path_201" data-name="Path 201" d="M9.813,0A9.813,9.813,0,1,1,.524,12.984,9.5,9.5,0,0,1,0,9.813,9.813,9.813,0,0,1,9.813,0Z" transform="translate(1632.215 462.919) rotate(-76.714)" fill="#2f2c2b"/>
    <g id="Group_208" data-name="Group 208">
      <path id="Path_158" data-name="Path 158" d="M1850.1,396.5c-1.4-2.6.8-6,3.7-6.8,2.8-.8,5.9.2,8.5,1.6,1.5.8,3.2,2.3,2.5,3.8-.5,1.3-2.3,1.5-3.7,1.5H1853c4.3,1.5,8.6,2.9,12.9,4.4a3.224,3.224,0,0,1,1.4.8,2.185,2.185,0,0,1-.4,2.9,5.455,5.455,0,0,1-3.1,1,25.424,25.424,0,0,1-8.7-.6c3,.6,6.1,1.2,9.1,1.7a10.014,10.014,0,0,1,4.8,1.8c1.3,1.1,1.8,3.4.6,4.6a4.729,4.729,0,0,1-3.7.9c-2.5-.1-5-.1-7.6-.2a65.557,65.557,0,0,1,9,4.9c1.2.8,2.6,2.1,1.9,3.4a2.434,2.434,0,0,1-1.4,1c-2.8,1-5.8-.2-8.3-1.8-.8-.5-2-1-2.5-.2-.6.8.1,2.6-1,2.6" fill="#ffd1bb"/>
    </g>
    <g id="Group_210" data-name="Group 210">
      <g id="Group_209" data-name="Group 209">
        <path id="Path_159" data-name="Path 159" d="M1824.8,304.6a51.041,51.041,0,0,1,3,12.1c.1.4.3.8.8.8.4,0,.9-.4.8-.8a55.277,55.277,0,0,0-3.1-12.6c-.3-.9-1.9-.5-1.5.5Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_212" data-name="Group 212">
      <g id="Group_211" data-name="Group 211">
        <path id="Path_160" data-name="Path 160" d="M1807.2,319.8c1.4,2,2.8,4,4.2,5.9a.829.829,0,0,0,1.1.3.748.748,0,0,0,.3-1.1c-1.4-2-2.8-4-4.2-5.9a.829.829,0,0,0-1.1-.3.748.748,0,0,0-.3,1.1Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_214" data-name="Group 214">
      <g id="Group_213" data-name="Group 213">
        <path id="Path_161" data-name="Path 161" d="M1849.4,321.5a28.41,28.41,0,0,0,3.7-8.8c.2-1-1.4-1.5-1.6-.4a26.058,26.058,0,0,1-3.6,8.4c-.5.8.9,1.7,1.5.8Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_216" data-name="Group 216">
      <g id="Group_215" data-name="Group 215">
        <path id="Path_162" data-name="Path 162" d="M1855,305.2a.8.8,0,1,0,0-1.6.8.8,0,1,0,0,1.6Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_218" data-name="Group 218">
      <g id="Group_217" data-name="Group 217">
        <path id="Path_163" data-name="Path 163" d="M1863.6,333.9l4.3-4.3a.849.849,0,0,0-1.2-1.2l-4.3,4.3a.849.849,0,0,0,1.2,1.2Z" fill="#2f2c2b"/>
      </g>
    </g>
    <g id="Group_235" data-name="Group 235" transform="translate(694.711 -1329.968) rotate(45)">
      <g id="Group_197-2" data-name="Group 197" transform="translate(0 42.562)">
        <g id="Group_196-2" data-name="Group 196" transform="translate(1920.856 405.225)">
          <path id="Path_148-2" data-name="Path 148" d="M1920.9,405.281v13.743c0,.187-.1.37,0,.557v.025a24.534,24.534,0,0,0,3.3,0V405.862c0-.187.1-.37,0-.557v-.025a24.544,24.544,0,0,0-3.3,0Z" transform="translate(-1920.856 -405.225)" fill="#2f2c2b"/>
        </g>
      </g>
      <path id="Path_153-2" data-name="Path 153" d="M1926.8,448.4h-9.4a2.9,2.9,0,0,1-2.9-2.9v-4.4a2.9,2.9,0,0,1,2.9-2.9h9.4a2.9,2.9,0,0,1,2.9,2.9v4.4A2.9,2.9,0,0,1,1926.8,448.4Z" transform="translate(0.45 20.342)" fill="#2f2c2b" stroke="#2f2c2b" strokeMiterlimit="10" strokeWidth="1.096"/>
    </g>
  </g>
</svg>

    )
}
