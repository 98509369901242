export const FlatGuitarist = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7537.676 4409.381">
      <g id="Group_43" data-name="Group 43" transform="translate(-706.327 -1337.45)">
        <path id="Path_199" data-name="Path 199" d="M1004.906,5746.831l7100.861-47.763s461.716-1337.382-302.5-1512.516S7659.974,2228.243,6816.149,1893.9s-1639.885,827.9-2404.1,15.922S2660.712,1177.443,2549.264,1973.5s334.345,1082.643-1066.721,1544.358S1004.906,5746.831,1004.906,5746.831Z" fill="#c8d4e8" opacity="0.25"/>
        <g id="Group_42" data-name="Group 42">
          <rect id="Rectangle_1" data-name="Rectangle 1" width="1110.505" height="1623.964" transform="translate(5665.841 4071.123)" fill="#a1b0ce"/>
          <rect id="Rectangle_2" data-name="Rectangle 2" width="764.529" height="1623.964" transform="translate(5665.841 4071.123)" fill="#c8d4e8"/>
          <g id="Group_4" data-name="Group 4">
            <path id="Path_200" data-name="Path 200" d="M0,0H61.535V836.109H0Z" transform="translate(4254.824 4846.042)" fill="#333"/>
            <rect id="Rectangle_6" data-name="Rectangle 6" width="1020.948" height="143.291" transform="translate(3460.753 4711.952)" fill="orange"/>
            <g id="Group_3" data-name="Group 3">
              <path id="Path_2" data-name="Path 2" d="M4638.546,5476.285l23.96,135.986-166.13,6.184-31.594-110.577Z" fill="#efd6bd"/>
              <path id="Path_3" data-name="Path 3" d="M4996.189,5441.366l2.588,138.057L4833.7,5559.777l-14.069-114.138Z" fill="#efd6bd"/>
              <path id="Path_4" data-name="Path 4" d="M5001.871,5476.285l-189.561,31.594-63.186-932.007L4401.6,4654.855l-53.392-378.49c210.252-51.812,458.422-113.736,603.277-101.256,33.328,3,61.289,9.794,81.984,21.641,18.8,10.742,31.435,45.337,39.491,96.518C5119.556,4595.143,5001.871,5476.285,5001.871,5476.285Z" fill="#18234c"/>
              <path id="Path_5" data-name="Path 5" d="M4875.5,3359.524c-2.684,2.685-6.633,22.115-12.163,52.445-1.107,6.16-2.368,12.953-3.633,20.061-27.645,152.123-91.464,493.964-252.748,543.566-205.357,63.187-284.341-157.967-284.341-157.967l-78.984-663.463c142.171,110.577,315.935,473.9,315.935,473.9l133.481-254.8,9.48-18.167,30.8-58.763S4891.294,3343.727,4875.5,3359.524Z" fill="orange"/>
              <path id="Path_6" data-name="Path 6" d="M4354.205,4465.295H3580.166c0-110.577,31.593-284.341,31.593-616.073s-94.78-631.869-94.78-631.869l219.257-104.416,42.654-20.378,56.075-26.7,13.745-6.476h236.951l25.431,16.27,148.333,94.307s47.39,78.984,142.171,379.122C4433.189,4007.19,4354.205,4465.295,4354.205,4465.295Z" fill="orange"/>
              <path id="Path_7" data-name="Path 7" d="M4654.343,5507.879l-189.561,63.187-284.341-884.617s-600.275,268.544-600.275-221.154c216.1-204.095,532.823-271.862,699.954-268.229,52.6,1.1,90.515,9.478,105.679,22.905,7.74,6.792,17.532,31.278,28.909,68.874C4496.06,4558.5,4654.343,5507.879,4654.343,5507.879Z" fill="#1f2a64"/>
              <g id="Group_2" data-name="Group 2">
                <path id="Path_10" data-name="Path 10" d="M4085.661,3059.386h-19.588a102.515,102.515,0,0,1-181.978,0H3848.71l-13.745,6.476a150.191,150.191,0,0,0,276.127,9.794Z" fill="#18234c"/>
              </g>
              <path id="Path_11" data-name="Path 11" d="M4054.068,3786.035l31.593,189.561s-29.7,15.322-76.932,36.964L3980.3,3794.249Z" fill="#1f2a64"/>
              <path id="Path_12" data-name="Path 12" d="M4875.5,3359.524c-2.684,2.685-6.633,22.115-12.163,52.445l-160.81-56.869,30.8-58.763S4891.294,3343.727,4875.5,3359.524Z" fill="#1f2a64"/>
              <path id="Path_13" data-name="Path 13" d="M4229.958,2761.813c-2.282,176.6-91.871,273.445-161.505,262.577-116.458-18.176-212.089-224.957-212.089-224.957-35.366-29.5-34.914-237.275,115.3-277.782S4250.168,2581.106,4229.958,2761.813Z" fill="#efd6bd"/>
              <path id="Path_14" data-name="Path 14" d="M3904,2877.724s-47.391-236.951,126.373-221.155,221.155-78.983,236.951-110.577-157.967-142.17-315.934-94.78-142.171,157.967-142.171,157.967l-31.593,31.594C3746.031,2672.366,3904,2877.724,3904,2877.724Z" fill="#1f2a64"/>
              <path id="Path_15" data-name="Path 15" d="M4065.6,3054.9l-10.222-137.364-159.277-63.51-10.448,197.348a58.415,58.415,0,0,0,53.947,61.339l63.359,4.772A58.416,58.416,0,0,0,4065.6,3054.9Z" fill="#efd6bd"/>
              <path id="Path_16" data-name="Path 16" d="M3651.251,3509.593l-15.8,331.731,232.517-26.049-153.533-52.935Z" fill="#1f2a64"/>
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="71.085" cy="71.085" r="71.085" transform="translate(3825.015 2656.57)" fill="#efd6bd"/>
              <path id="Path_18" data-name="Path 18" d="M3951.389,2861.927s15.8,110.577,110.577,157.967l.138,44.623S3935.592,3051.488,3951.389,2861.927Z" fill="#1f2a64"/>
              <path id="Path_19" data-name="Path 19" d="M4780.224,3193.467l-427.883,423.9L4205.07,3442.235l740.336-616.947,67.665,123.389Z" fill="#bea15f"/>
              <path id="Path_20" data-name="Path 20" d="M3779.178,3533.782s79.606,71.646,107.468-39.8,67.665-214.936,179.114-175.133,318.424,230.858,354.247,354.247,68.957,208.056-21.246,261.25-157.868,29.312-197.671,120.859-143.291,457.735-402.011,441.814-589.085-449.774-501.518-664.711S3512.5,3414.373,3779.178,3533.782Z" fill="#d39f4c"/>
              <path id="Path_21" data-name="Path 21" d="M4085.661,3975.6s-29.7,15.322-76.932,36.964c-.154,0-.154.158-.316.158-156.544,72.033-505.017,214.2-602.011,105.049-126.374-142.171,31.593-868.82,110.577-900.414,0,0,47.39,47.39,78.984,173.764s31.593,442.309,31.593,442.309l339.63-37.755,13.112-1.422,73.77-8.214Z" fill="orange"/>
              <path id="Path_22" data-name="Path 22" d="M4039.95,3805.484s91.65-50.379,154.837-34.582,126.374,78.984,110.577,126.374-126.374,15.8-126.374,15.8l-114.135,41.84Z" fill="#efd6bd"/>
              <path id="Path_23" data-name="Path 23" d="M4863.811,2860.116l107.468,171.153,210.956-179.114-129.36-177.124Z" fill="orange"/>
              <path id="Path_24" data-name="Path 24" d="M4751.214,3301.9S4764.92,3012,4859.7,3043.589s94.78,156.235,0,299.272Z" fill="#efd6bd"/>
            </g>
            <path id="Path_25" data-name="Path 25" d="M4493.565,5597.768l167.911-5.417,200.41,124.579-373.737-16.249Z" fill="#333"/>
            <path id="Path_26" data-name="Path 26" d="M4832.372,5544.157l-5.417,119.162,319.573-27.082-146.245-70.415Z" fill="#333"/>
            <rect id="Rectangle_7" data-name="Rectangle 7" width="64.334" height="822.85" transform="translate(3586.133 4854.999)" fill="#333"/>
          </g>
          <g id="Group_8" data-name="Group 8">
            <g id="Group_7" data-name="Group 7">
              <path id="Path_34" data-name="Path 34" d="M2723.279,4839.587c-70.691,139.244-135.5,138.385-191.2,110.109s-81.193-73.263-10.5-212.507,254.655-323.122,310.353-294.846S2793.97,4700.342,2723.279,4839.587Z" fill="#1c90ff"/>
              <path id="Path_35" data-name="Path 35" d="M2283.493,5061.979c53.874,110.075,109.008,108.054,157.62,84.262s72.3-60.349,18.421-170.423-202.05-253.57-250.663-229.777S2229.618,4951.9,2283.493,5061.979Z" fill="#1c90ff"/>
              <path id="Path_36" data-name="Path 36" d="M2305.055,4682.833c43.543,88.965,88.1,87.332,127.393,68.1s58.431-48.775,14.888-137.74-163.3-204.941-202.592-185.711S2261.512,4593.868,2305.055,4682.833Z" fill="#1c90ff"/>
              <path id="Path_37" data-name="Path 37" d="M2340.156,4596.648s116.872,154.573,165.883,392.086,33.93,448.637,33.93,448.637" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
              <path id="Path_38" data-name="Path 38" d="M2687,4653.2s-154.573,196.043-196.043,497.648-45.241,320.455-45.241,320.455" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
              <path id="Path_39" data-name="Path 39" d="M2310,4905.793s218.663,278.984,218.663,591.9" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
            </g>
            <path id="Path_40" data-name="Path 40" d="M2694.542,5503.347c0,111.4-86.928,201.7-194.158,201.7s-194.158-90.3-194.158-201.7,92.582-122.527,199.813-122.527S2694.542,5391.952,2694.542,5503.347Z" fill="orange"/>
          </g>
          <g id="Group_10" data-name="Group 10">
            <g id="Group_9" data-name="Group 9">
              <path id="Path_41" data-name="Path 41" d="M6312.8,3643.639c-35.731,70.38-68.488,69.946-96.641,55.654s-41.038-37.03-5.307-107.411,128.714-163.32,156.866-149.028S6348.532,3573.258,6312.8,3643.639Z" fill="#1c90ff"/>
              <path id="Path_42" data-name="Path 42" d="M6090.513,3756.046c27.231,55.637,55.1,54.616,79.669,42.59s36.542-30.5,9.311-86.14-102.125-128.165-126.7-116.139S6063.283,3700.41,6090.513,3756.046Z" fill="#1c90ff"/>
              <path id="Path_43" data-name="Path 43" d="M6101.412,3564.408c22.009,44.967,44.531,44.142,64.39,34.422s29.534-24.653,7.526-69.62-82.541-103.587-102.4-93.867S6079.4,3519.441,6101.412,3564.408Z" fill="#1c90ff"/>
              <path id="Path_44" data-name="Path 44" d="M6119.154,3520.847s59.072,78.128,83.845,198.178,17.15,226.762,17.15,226.762" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
              <path id="Path_45" data-name="Path 45" d="M6294.466,3549.43s-78.128,99.089-99.09,251.534-22.866,161.973-22.866,161.973" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
              <path id="Path_46" data-name="Path 46" d="M6103.91,3677.1s110.522,141.011,110.522,299.173" fill="none" stroke="#1f2a64" strokeMiterlimit="10" strokeWidth="6"/>
            </g>
            <path id="Path_47" data-name="Path 47" d="M6298.277,3979.134c0,56.3-43.937,101.948-98.137,101.948S6102,4035.438,6102,3979.134s46.795-61.931,101-61.931S6298.277,3922.83,6298.277,3979.134Z" fill="orange"/>
          </g>
        </g>
      </g>
    </svg>

    )
}
