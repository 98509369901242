export const FlatFemaleGuitarist = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1013.357 1055.222">
            <g id="Group_41" data-name="Group 41" transform="translate(-519.284 -176.251)">
                <g id="Group_40" data-name="Group 40" transform="translate(191 -155)">
                <g id="Group_7" data-name="Group 7">
                    <path id="Path_51" data-name="Path 51" d="M1212.028,443.2a436.969,436.969,0,0,0-92.483-49.842c-86.391-33.569-180.713-41.308-273.39-40.167-167.1,2.059-415.211,36.737-497.836,206.664-54.938,112.987-19.864,243.125,25.985,352.67,110.7,264.488,368.406,512.66,676.56,465.75a475.984,475.984,0,0,0,127.687-38.5c52-24.007,116.884-65.27,113.736-130.021-3.114-64.032-49.1-120.434-79.951-173.69-41.4-71.459-39.907-138.567,10.089-203.8,40.052-52.255,96.632-96.617,108.6-165.045C1347.122,575.129,1286.748,496,1212.028,443.2Z" transform="translate(8 -1)" fill="#c8d4e8"/>
                </g>
                <g id="Group_31" data-name="Group 31">
                    <path id="Path_198" data-name="Path 198" d="M0,0H38.246V470.51H0Z" transform="translate(826.641 887.357) rotate(-0.437)" fill="#333"/>
                    <path id="Path_92" data-name="Path 92" d="M991.63,1384.325l-281.364,2.147c-26.259.2-26.572-40.775-.312-40.975l281.364-2.147C1011.713,1343.194,1012.025,1384.17,991.63,1384.325Z" fill="#666"/>
                    <path id="Path_93" data-name="Path 93" d="M969.338,1211.07,729.267,1212.9c-22.405.17-22.54-17.433-.134-17.6l240.07-1.832C986.6,1193.334,986.739,1210.937,969.338,1211.07Z" fill="#333"/>
                    <path id="Path_94" data-name="Path 94" d="M1075.847,556.647c-2.9,4.656-7.407,10.569-10.171,1.792-.668-2.121.369-7.326-1.34-8.85-2.962-2.634-4.672,3.382-5.5,5.655-5.825,16.012-5.688,38.339,11.679,46.564,13.072,6.19,26.419-4.209,33.205-14.642,6.663-10.244,8.879-22.368,2.829-33.2-1.611-2.879-3.5-5.14-4.609-8.041-1.064-2.78-1.48-5.615-2.86-8.3-.809-1.578-4.44-6.939-6.292-6.781-2.312.2-4.821,5.263-5.838,6.908-3.547,5.736-6.822,11.767-10.3,17.575C1076.4,555.74,1076.133,556.187,1075.847,556.647Z" fill="#bd7b63"/>
                    <path id="Path_95" data-name="Path 95" d="M944.874,942.209l-210.993,1.609c-15.594.119-16.156-73.557-.562-73.676,71.421-12.373,141.79-13.322,210.993-1.61C967.129,868.358,967.692,942.034,944.874,942.209Z" fill="#333"/>
                    <path id="Path_97" data-name="Path 97" d="M780.994,367.949c-1.469.5-15.514-37.474-42.521-36.156s-48.085,24.075-44.105,54.5,7.7,66.749-9.957,70.813c0,0,35.947,6.112,51.707-18.079l3.248,9.8s20.886-22.756,6.637-59.489,27.23-8.559,27.23-8.559Z" fill="#1c90ff"/>
                    <path id="Path_99" data-name="Path 99" d="M779.561,365.978c-10.823-8.594-28.456-19.455-44.546-15.2-25.492,6.744-5.279,37.7-2.381,52.744s1.919,15.377-7.523,22.654c-7.6,5.854-6,23.29-17.87,30.494,10.273-1.876,21.679-6.594,28.877-17.643l3.248,9.8s20.886-22.756,6.637-59.489,27.23-8.559,27.23-8.559l7.761-12.831C780.781,368.022,780.3,367.283,779.561,365.978Z" fill="#231f20" opacity="0.18"/>
                    <path id="Path_100" data-name="Path 100" d="M885.609,359.455s8.728,12.705,4.286,31.406-28.558,34.276-30.267,24.792,20.148-24.715,15.6-39.745S885.609,359.455,885.609,359.455Z" fill="#1c90ff"/>
                    <g id="Group_18" data-name="Group 18">
                    <path id="Path_101" data-name="Path 101" d="M1014.034,1278.149c4.041-.905,5.928-6.425,10.1-7.554-10.88-9.463-23.571-18.759-20.051-34.763-7.954-1.888-15.916-3.454-23.969-4.872-4.061,9.528-7.851,19.1-11.555,28.793a67.658,67.658,0,0,1,7.42,5.7C987.133,1274.707,999.282,1281.452,1014.034,1278.149Z" fill="#bd7b63"/>
                    <path id="Path_102" data-name="Path 102" d="M1024.132,1270.595c-4.158,1.124-6.07,6.653-10.1,7.556-14.732,3.3-26.886-3.446-38.062-12.695a65.689,65.689,0,0,0-7.409-5.7c-1.992,5.184-4,10.421-5.993,15.626,27.283,10.927,55.328,20.15,83.033,29.93C1060.023,1292.965,1033.417,1278.28,1024.132,1270.595Z" fill="#1c90ff"/>
                    <g id="Group_16" data-name="Group 16">
                        <path id="Path_103" data-name="Path 103" d="M895.315,703.921c-30.76,54.421-56.378,165.307,1.671,180.132,68.657,17.534,116.061,55.37,121.5,83.753s-.828,145.313-43.471,261.406c5.158.464,36.414,9.138,36.414,9.138s94.141-213.054,81.479-307.785c-6.575-49.19-50.971-87.27-109.551-123.649C822.737,707.17,998.042,772.033,895.315,703.921Z" fill="#1f2a64"/>
                    </g>
                    <g id="Group_17" data-name="Group 17" opacity="0.15">
                        <path id="Path_104" data-name="Path 104" d="M1049.005,991.558c1.717-28.891-11.822-91.584-73.94-125.709-24.577-13.5-26.253-42.845-16.662-74.87-116-76.637,33.6-22.949-63.088-87.058-30.76,54.421-56.378,165.307,1.671,180.132,68.658,17.534,116.061,55.371,121.5,83.753,4.674,24.4.7,114.232-27.6,212.717C1043.9,1090.762,1047.509,1016.734,1049.005,991.558Z"/>
                    </g>
                    <path id="Path_105" data-name="Path 105" d="M987,1273.406a13.981,13.981,0,0,1-8.034-18.11q.5-1.3,1-2.594c.117.07.231.148.347.221-.115-.073-.229-.151-.345-.221,2.6-6.815,5.257-13.569,8.011-20.291q-3.921-.75-7.861-1.451c-4.061,9.528-7.851,19.1-11.555,28.793.115.071.23.149.345.222-.116-.073-.231-.151-.347-.222-1.992,5.184-4,10.421-5.993,15.626,27.283,10.927,55.328,20.15,83.033,29.93,3.64-3.115,4.665-6.378,4.018-9.641C1028.722,1288.368,1007.7,1281.267,987,1273.406Z" opacity="0.15"/>
                    </g>
                    <g id="Group_22" data-name="Group 22">
                    <path id="Path_107" data-name="Path 107" d="M980.729,1200.261c3.95-1.21,5.373-6.816,9.434-8.258-11.619-8.508-25.029-16.711-22.846-32.811-8.079-1.246-16.139-2.174-24.277-2.949-3.257,9.738-6.241,19.5-9.129,29.372a67.937,67.937,0,0,1,7.862,5.057C953.65,1198.954,966.307,1204.676,980.729,1200.261Z" fill="#bd7b63"/>
                    <path id="Path_108" data-name="Path 108" d="M990.162,1192c-4.049,1.435-5.5,7.051-9.44,8.259-14.4,4.409-27.065-1.312-38.962-9.587a66.038,66.038,0,0,0-7.85-5.06c-1.557,5.28-3.12,10.615-4.678,15.918,28.078,8.678,56.775,15.611,85.179,23.122C1027.76,1211.325,1000.046,1198.879,990.162,1192Z" fill="#1c90ff"/>
                    <g id="Group_19" data-name="Group 19">
                        <path id="Path_109" data-name="Path 109" d="M774.24,751.685C746.7,794.44,780.255,888.262,840.157,889.2c69.46,1.092,99.175-10.705,118.767,10.282s-24.074,142.237-16.669,259.143c4.624-2.328,24.192.981,35.575.784,10.189-130.014,113.929-261.392,53.194-334.328C999.487,787.211,868.176,763.7,868.176,763.7Z" fill="#1f2a64"/>
                        <path id="Path_110" data-name="Path 110" d="M957.281,1126.039c-7.405-116.906,36.262-238.156,16.669-259.143s-49.306-9.189-118.767-10.281c-47.006-.739-77.784-58.67-75.55-104.24l-5.393-.69C746.7,794.44,780.255,888.262,840.157,889.2c69.46,1.092,99.175-10.705,118.767,10.282s-24.074,142.237-16.669,259.143c4.624-2.327,24.193.981,35.575.784a314.942,314.942,0,0,1,4.445-33.018C971.977,1125.642,960.654,1124.342,957.281,1126.039Z" opacity="0.15" />
                    </g>
                    <g id="Group_20" data-name="Group 20">
                        <path id="Path_111" data-name="Path 111" d="M945.848,1197.31a4.842,4.842,0,0,1-3.234-6l.013-.046c-.288-.2-.579-.387-.866-.588-1.385-.963-2.845-2.01-4.343-2.993,1.815,1.185,3.578,2.46,5.2,3.576l.011-.005c1.1-3.761,2.209-7.525,3.311-11.262.209.1.413.232.622.344-.208-.112-.413-.24-.62-.345,2.229-7.624,4.52-15.18,6.946-22.711q-4.912-.562-9.849-1.037c-3.257,9.738-6.241,19.5-9.128,29.372.207.1.412.233.619.345-.208-.111-.413-.24-.621-.344-1.557,5.28-3.12,10.615-4.678,15.918,28.078,8.678,56.775,15.611,85.179,23.122,2.668-2.664,3.692-5.291,3.55-7.847C993.884,1210.532,969.663,1204.5,945.848,1197.31Z" opacity="0.15" />
                    </g>
                    <g id="Group_21" data-name="Group 21">
                        <path id="Path_112" data-name="Path 112" d="M980.146,1139.123c.025-.168.049-.336.074-.5C980.2,1138.787,980.171,1138.955,980.146,1139.123Z" fill="#fff"/>
                        <path id="Path_114" data-name="Path 114" d="M980.838,1134.648l0-.024Z" fill="#fff"/>
                        <path id="Path_115" data-name="Path 115" d="M981.487,1130.782l.1-.545Z" fill="#fff"/>
                        <path id="Path_116" data-name="Path 116" d="M977.83,1159.412c-.711.012-1.455.011-2.224,0,.769.013,1.513.014,2.224,0v0Z" fill="#fff"/>
                        <path id="Path_117" data-name="Path 117" d="M978.149,1155.7q.05-.532.1-1.064Q978.2,1155.166,978.149,1155.7Z" fill="#fff"/>
                        <path id="Path_118" data-name="Path 118" d="M978.538,1151.78c.033-.318.066-.636.1-.955C978.6,1151.144,978.571,1151.462,978.538,1151.78Z" fill="#fff"/>
                        <path id="Path_119" data-name="Path 119" d="M979.072,1147.061l.024-.193Z" fill="#fff"/>
                        <path id="Path_120" data-name="Path 120" d="M979.561,1143.265c.037-.278.076-.556.114-.834C979.637,1142.709,979.6,1142.987,979.561,1143.265Z" fill="#fff"/>
                    </g>
                    </g>
                    <path id="Path_121" data-name="Path 121" d="M1059.693,585.362,919.025,676.119l7.14,14.586a17.736,17.736,0,0,0,26.843,6.181l119.629-93.4Z" fill="#bd7b63"/>
                    <path id="Path_122" data-name="Path 122" d="M917.57,561.034,960.7,668.427l-41.67,7.692s-42.7-82.672-56.177-89.522C825.156,548.084,879.5,484.162,917.57,561.034Z" fill="#ffd4d4"/>
                    <path id="Path_124" data-name="Path 124" d="M836.881,562.116a124.575,124.575,0,0,1,.114-26.045c1.57-15.41,10.7-46.42,27.752-51.387l.857-.249c6.878-2,20.5,6.864,25.372,11.045,6.959,5.973,12.733,13.783,17.506,21.564,9.827,16.011,36.246,91.217,36.246,91.217l-62.769,14.186S870.7,606.7,862.625,600.675c-10.806-8.066-19.355-10.73-23.175-24.783A89.675,89.675,0,0,1,836.881,562.116Z" fill="orange"/>
                    <path id="Path_125" data-name="Path 125" d="M846.945,529.527a86.542,86.542,0,0,0-.18,9.227,124.562,124.562,0,0,0,3.574,25.8,89.547,89.547,0,0,0,4.493,13.273c5.771,13.371,14.61,14.8,26.449,21.253C890.132,603.9,903.5,617.9,903.5,617.9l13.673-5.213a245.232,245.232,0,0,0-2.382-29.284C909.148,549.584,878.705,530.392,846.945,529.527Z" opacity="0.15" />
                    <path id="Path_126" data-name="Path 126" d="M909.91,667.014c-7.033-21.4-8.364-43.334-3.709-63.01,2.892-12.23,8.939-23.023,10.8-35.028,1.9-12.253-3.615-21.369-12.077-29.6C891.182,526,877.339,513.257,870.3,496.06c-2.691-6.58-2.009-11.347-9.568-12.391-10.317-1.425-24.346,2.459-34.606,3.766-29.385,3.744-108.925,24.179-92.5,72.951,3.925,11.652,12.148,20.02,19.432,29.6,11.923,15.672,23.171,28.1,31.109,48.9,7.406,19.4,13.576,41.844,13.478,65.34-.123,29.168-49.471,44.679-25.023,57.019,28.81,14.541,74.879,20.53,107.7,15.7,28.272-4.157,32.7.4,58.157-17.636.831-.588-21.257-73.137-25.826-84.7Q911.164,670.827,909.91,667.014Z" fill="#e29200"/>
                    <path id="Path_127" data-name="Path 127" d="M912.655,674.6q-1.491-3.775-2.745-7.588a131.093,131.093,0,0,1-4.762-19.27,82.036,82.036,0,0,1-24.8,38.9c-23.777,19.677-53.724,28.092-83.874,25.216-7.341,24.323-46.068,38.158-23.85,49.373,28.81,14.541,74.879,20.53,107.7,15.7,28.271-4.157,32.7.4,58.157-17.636C939.311,758.717,917.223,686.168,912.655,674.6Z" opacity="0.15" />
                    <path id="Path_128" data-name="Path 128" d="M787.365,647.721c10.286-32.044,9.9-64.888-12.661-84.692-3.33,13.149-6.765,26.97-11.215,40.432,7.917,10.358,15.1,20.811,20.681,35.416Q785.822,643.2,787.365,647.721Z" opacity="0.15" />
                    <path id="Path_129" data-name="Path 129" d="M861.133,438.3l-32.269-3.616-32.21,4.108s18.875,42.6,13.567,51.453c-11.688,19.488,37.5,36.542,37.5,36.542s17.724-17.14,5.479-41.023C848.448,476.488,861.133,438.3,861.133,438.3Z" fill="#bd7b63"/>
                    <g id="Group_24" data-name="Group 24">
                    <path id="Path_131" data-name="Path 131" d="M842.979,343.479c-8.574-2.087-36.456-4.09-49.763,17.291-9.66,15.519-10.546,30.557-9.3,47.3,1.433,19.235,5.481,40.733,20.911,53.886,9.064,7.726,21.488,10.991,33.241,10.639,9.92-.3,25.62-1.707,32.111-10.53,5.986-8.138,3-19.007,2.925-28.406-.081-10.384-1.995-21.277.31-31.4,1.145-5.02,6.878-20.71,12.326-25.745C882.532,363.589,858.817,347.334,842.979,343.479Z" fill="#bd7b63"/>
                    </g>
                    <path id="Path_135" data-name="Path 135" d="M890.075,371.538s-6.193,19.205-29.322,16.925-47.641,3.754-51.974,15.625,1.707,30.537-14.993,30.863-71.292-51.246-18.705-69.413c0,0-6.007-14.691,10.094-29.06S903.577,338.522,890.075,371.538Z" fill="#1c90ff"/>
                    <path id="Path_137" data-name="Path 137" d="M865.55,376.31c-25.563-2.425-57.676-4.964-66.428,6.811s-.208,15.685-8.671,22.44c-7.941,6.338-20.923-5.638-28.612,6.6,9.657,12.849,24.534,22.935,31.947,22.79,16.7-.326,10.66-18.992,14.993-30.863s28.846-17.9,51.975-15.625,29.321-16.925,29.321-16.925a16.181,16.181,0,0,0,1.133-4.383C886.43,372.475,878.425,377.531,865.55,376.31Z" fill="#1c90ff" opacity="0.18" />
                    <g id="Group_26" data-name="Group 26">
                    <path id="Path_138" data-name="Path 138" d="M770.959,425.814c-3.354,9.753,7,21.546,17.269,20.564s8.945-2.563,8.945-2.563-2.2-30.061-12.472-29.082S774.433,415.714,770.959,425.814Z" fill="#bd7b63"/>
                    </g>
                    <g id="Group_29" data-name="Group 29">
                    <path id="Path_140" data-name="Path 140" d="M878.671,620.641a93.182,93.182,0,0,0-70.985,63.764c-4.435,14.426-17.161,25-32.171,27.891a107.681,107.681,0,0,0-17.483,4.929c-44.879,16.825-73.27,62.7-67.581,109.512,7.461,61.4,66.452,101.547,126.5,88.536,48.046-10.411,81.371-51.531,83.229-97.767.352-8.778,6.912-15.885,15.636-17.757l.164-.036c51.38-11.133,83.715-62.037,70.692-112.57C974.713,640.756,926.423,611.048,878.671,620.641Z" fill="orange"/>
                    <g id="Group_27" data-name="Group 27">
                        <path id="Path_141" data-name="Path 141" d="M1214.868,382.12a4.236,4.236,0,1,0-4.8-6.98l-5.43,3.735a4.236,4.236,0,0,0,4.8,6.98l.726-.5,9.329,10.815,3.412-3.147-9.257-10.065Z" fill="#ffc06c"/>
                        <path id="Path_142" data-name="Path 142" d="M1189.8,390.923a4.237,4.237,0,0,0-4.8-6.981l-5.429,3.735a4.237,4.237,0,0,0,4.8,6.981l1.041-.716,8.412,9.753,3.412-3.148-8.3-9.028Z" fill="#ffc06c"/>
                        <path id="Path_143" data-name="Path 143" d="M1245.7,385.233l-8.71-9.469,1.126-.775a4.237,4.237,0,0,0-4.8-6.981l-5.429,3.736a4.236,4.236,0,0,0,4.8,6.98l.8-.549,8.8,10.206Z" fill="#ffc06c"/>
                    </g>
                    <g id="Group_28" data-name="Group 28">
                        <path id="Path_144" data-name="Path 144" d="M1248.372,420.782l-.9.619-7.524-8.18-4.6,1,8.4,9.742-.808.557a4.236,4.236,0,0,0,4.8,6.98l5.429-3.735a4.236,4.236,0,1,0-4.8-6.98Z" fill="#ffc06c"/>
                        <path id="Path_145" data-name="Path 145" d="M1270.438,401.99a4.249,4.249,0,0,0-5.891-1.089l-2.22,1.527-9.3-10.115-4.6,1,10.206,11.833a4.2,4.2,0,0,0-.6,5.386,4.251,4.251,0,0,0,5.892,1.089l5.429-3.735A4.25,4.25,0,0,0,1270.438,401.99Z" fill="#ffc06c"/>
                        <path id="Path_146" data-name="Path 146" d="M1224.374,441.172l-.252.173-7.112-7.732-4.6,1,8.008,9.284-1.473,1.013a4.236,4.236,0,0,0,4.8,6.98l5.429-3.735a4.236,4.236,0,0,0-4.8-6.98Z" fill="#ffc06c"/>
                    </g>
                    <path id="Path_147" data-name="Path 147" d="M905.35,614.86A91.374,91.374,0,0,0,835.7,678.334c-4.315,14.4-16.8,24.927-31.568,27.76a104.21,104.21,0,0,0-17.19,4.865c-44.114,16.66-71.9,62.4-66.141,109.2a103.6,103.6,0,0,0,206.384-8.507,18.958,18.958,0,0,1,15.328-17.69l.163-.035a91.464,91.464,0,0,0,69.19-112.246C999.945,635.288,952.316,605.437,905.35,614.86Z" fill="#f4d793"/>
                    <path id="Path_148" data-name="Path 148" d="M1253.233,374.447c-22.971,18.463-61.89,21.117-72.133,27.19s-8.3,21.16-8.3,21.16L925.565,671.032l24.824,24.723L1197.62,447.52c17.224,9.127,69.853-60.7,69.853-60.7Z" fill="#e29200"/>
                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="39" cy="39" r="39" transform="translate(835 712)" fill="#e29200"/>
                    </g>
                    <path id="Path_150" data-name="Path 150" d="M715.617,591.852l-22.09,108.053,42.415,5.232s26.307-89.253,38.244-98.526C803.918,561.669,738.474,509.17,715.617,591.852Z" fill="#bd7b63"/>
                    <path id="Path_151" data-name="Path 151" d="M848.7,757.526l-141.039-24.87a20.7,20.7,0,0,1-16.469-25.488l5.824-22.917,157.3,51.721Z" fill="#bd7b63"/>
                    <path id="Path_152" data-name="Path 152" d="M885.016,740.264c-5.362-1.144-12.455-3.38-5.138-8.958,1.768-1.348,7.017-2.14,7.869-4.265,1.473-3.68-4.767-3.247-7.185-3.252-17.039-.044-37.993,7.663-39.835,26.791-1.385,14.4,12.927,23.422,25.043,26.264,11.9,2.791,24.055.759,32.192-8.61,2.162-2.492,3.647-5.035,6-7.064,2.255-1.945,4.779-3.3,6.841-5.51,1.211-1.3,5.02-6.532,4.242-8.219-.972-2.108-6.586-2.749-8.478-3.147-6.6-1.39-13.384-2.423-20.028-3.721C886.058,740.479,885.546,740.378,885.016,740.264Z" fill="#bd7b63"/>
                    <path id="Path_154" data-name="Path 154" d="M799.053,579.962a124.6,124.6,0,0,0-2.677-25.907c-3.08-15.18-15.222-45.139-32.679-48.4l-.878-.164c-7.042-1.315-19.726,8.851-24.161,13.491-6.337,6.63-11.312,14.971-15.3,23.183-8.2,16.9-27.086,94.345-27.086,94.345l63.86,7.933s9.65-16.775,17.1-23.57c9.959-9.091,18.2-12.584,20.622-26.945A89.673,89.673,0,0,0,799.053,579.962Z" fill="orange"/>
                </g>
                </g>
            </g>
        </svg>
    )
}
